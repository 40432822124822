import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav } from '@uniqore/module'
import {
  UqTypography,
  UqTextField,
  UqMenuItem,
  UqSwitch,
  UqFormGroup,
  UqFormControlLabel,
  UqCollapse
} from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import {
  editMovingService,
  citiesOptions,
  surfaceAreaHomeOptions,
  surfaceAreaCompanyOptions,
  floorOptions
} from 'Features/movingService'

const MovingFrom: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['moving_service', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const {
    movingType,
    moveFromCity,
    surfaceAreaHome,
    surfaceAreaCompany,
    moveFromFloor,
    moveFromElevator,
    needMoveBoxes,
    movingBoxAmount
  } = useTypedSelector(state => state.movingService)

  const [moveFromCityValid, setMoveFromCityValid] = useState<string>('')
  const [moveFromFloorValid, setMoveFromFloorValid] = useState<string>('')
  const [homeSurfaceValid, setHomeSurfaceValid] = useState<string>('')
  const [companySurfaceValid, setCompanySurfaceValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (moveFromCity === '') {
      setMoveFromCityValid(t('common:error_input'))
      valid = false
    } else {
      setMoveFromCityValid('')
    }
    if (moveFromFloor === '') {
      setMoveFromFloorValid(t('common:error_input'))
      valid = false
    } else {
      setMoveFromFloorValid('')
    }
    if (movingType === 'home') {
      if (surfaceAreaHome === '') {
        setHomeSurfaceValid(t('common:error_input'))
        valid = false
      } else {
        setHomeSurfaceValid('')
      }
    }
    if (movingType === 'company') {
      if (surfaceAreaCompany === '') {
        setCompanySurfaceValid(t('common:error_input'))
        valid = false
      } else {
        setCompanySurfaceValid('')
      }
    }

    return valid
  }

  const handleForward = () => {
    if (validateFields()) {
      navigate(`/${lang}/moving-service/moving-to`)
    }
  }

  const handleFillFields = () => {
    dispatch(editMovingService({ key: 'moveFromCity', value: 'Espoo' }))
    dispatch(editMovingService({ key: 'moveFromFloor', value: '7' }))
    if (movingType === 'home') {
      dispatch(editMovingService({ key: 'surfaceAreaHome', value: '51-100 m2' }))
    }
    if (movingType === 'company') {
      dispatch(editMovingService({ key: 'surfaceAreaCompany', value: '101-200 m2' }))
    }
  }

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      top={
        <NavBar
          fillFields={handleFillFields}
          navigateTo={`/${lang}/moving-service/welcome`}
          text={t('common:back')}
          progressValue={0}
        />
      }
      bottom={
        <FlowNav
          actionComponents={[
            {
              text: t('common:forward'),
              color: 'primary',
              onClick: handleForward
            }
          ]}
        />
      }
    >
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('moving_service:moving_from_title')}
      </UqTypography>
      <UqTextField
        select
        label={t('moving_service:moving_from_input_city')}
        fullWidth
        variant="outlined"
        marginTop={16}
        marginBottom={8}
        value={moveFromCity}
        onChange={e => dispatch(editMovingService({ key: 'moveFromCity', value: e.target.value }))}
        error={moveFromCityValid ? true : false}
        helperText={moveFromCityValid}
      >
        {citiesOptions.map((option, i) => (
          <UqMenuItem key={i} value={option}>
            {option}
          </UqMenuItem>
        ))}
      </UqTextField>
      {movingType === 'home' ? (
        <UqTextField
          select
          label={t('moving_service:moving_from_input_surface')}
          fullWidth
          variant="outlined"
          marginVertical={8}
          value={surfaceAreaHome}
          onChange={e =>
            dispatch(editMovingService({ key: 'surfaceAreaHome', value: e.target.value }))
          }
          error={homeSurfaceValid ? true : false}
          helperText={homeSurfaceValid}
        >
          {surfaceAreaHomeOptions.map((option, i) => (
            <UqMenuItem key={i} value={option}>
              {option}
            </UqMenuItem>
          ))}
        </UqTextField>
      ) : (
        <UqTextField
          select
          label={t('moving_service:moving_from_input_surface')}
          fullWidth
          variant="outlined"
          marginVertical={8}
          value={surfaceAreaCompany}
          onChange={e =>
            dispatch(editMovingService({ key: 'surfaceAreaCompany', value: e.target.value }))
          }
          error={companySurfaceValid ? true : false}
          helperText={companySurfaceValid}
        >
          {surfaceAreaCompanyOptions.map((option, i) => (
            <UqMenuItem key={i} value={option}>
              {option}
            </UqMenuItem>
          ))}
        </UqTextField>
      )}
      <UqTextField
        select
        label={t('moving_service:moving_from_input_floor')}
        fullWidth
        variant="outlined"
        marginVertical={8}
        value={moveFromFloor}
        onChange={e => dispatch(editMovingService({ key: 'moveFromFloor', value: e.target.value }))}
        error={moveFromFloorValid ? true : false}
        helperText={moveFromFloorValid}
      >
        {floorOptions.map((option, i) => (
          <UqMenuItem key={i} value={option}>
            {option}
          </UqMenuItem>
        ))}
      </UqTextField>
      <UqFormGroup marginVertical={8}>
        <UqFormControlLabel
          control={
            <UqSwitch
              checked={moveFromElevator}
              onChange={e =>
                dispatch(editMovingService({ key: 'moveFromElevator', value: e.target.checked }))
              }
              size="medium"
              color="primary"
            />
          }
          label={t('moving_service:moving_from_switch_elevator')}
        />
      </UqFormGroup>
      {movingType === 'home' && (
        <>
          <UqFormGroup marginVertical={8}>
            <UqFormControlLabel
              control={
                <UqSwitch
                  checked={needMoveBoxes}
                  onChange={e =>
                    dispatch(editMovingService({ key: 'needMoveBoxes', value: e.target.checked }))
                  }
                  size="medium"
                  color="primary"
                />
              }
              label={t('moving_service:moving_from_switch_box')}
            />
          </UqFormGroup>
          <UqCollapse in={needMoveBoxes} timeout="auto" unmountOnExit>
            <UqTextField
              type="number"
              marginVertical={8}
              value={movingBoxAmount}
              onChange={e =>
                dispatch(
                  editMovingService({
                    key: 'movingBoxAmount',
                    value: Number(e.target.value)
                  })
                )
              }
              fullWidth
              label={t('moving_service:moving_form_input_box')}
            />
          </UqCollapse>
        </>
      )}
    </Layout>
  )
}

export default MovingFrom
