import React, { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav, Image } from '@uniqore/module'
import { UqTypography, UqDivider } from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { setAlphaToHex } from 'Components/Common/ColorPicker'

const MovingPrice: React.FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['moving_service', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const {
    surfaceAreaHome,
    moveFromCity,
    moveToCity,
    needMoveBoxes,
    movingBoxAmount,
    additionalInfo
  } = useTypedSelector(state => state.movingService)

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      top={
        <NavBar
          navigateTo={`/${lang}/moving-service/contact-person`}
          text={t('common:back')}
          progressValue={80}
        />
      }
      bottom={
        <FlowNav
          actionComponents={[
            {
              text: t('moving_service:moving_price_button'),
              color: 'primary',
              onClick: () => navigate(`/${lang}/moving-service/summary`)
            }
          ]}
        />
      }
    >
      <Image
        src="https://assets.website-files.com/62862937efdfeff32720287c/630dc8975fffcc6b0a2dfee3_Space-Email-check-uniqore.svg"
        width={80}
        center
        marginVertical={24}
      />
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('moving_service:moving_price_title')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('moving_service:moving_price_desc')}
      </UqTypography>
      <UqDivider variant="fullWidth" marginVertical={16} light />
      <UqTypography variant="body2" color="text.secondary" align="center">{`${t(
        'moving_service:moving_price_details_apartment'
      )}: ${surfaceAreaHome}`}</UqTypography>
      <UqTypography
        variant="body2"
        color="text.secondary"
        align="center"
      >{`${moveFromCity} - ${moveToCity}`}</UqTypography>
      {needMoveBoxes && movingBoxAmount > 0 ? (
        <UqTypography
          variant="body2"
          color="text.secondary"
          align="center"
        >{`${movingBoxAmount} ${t(
          'moving_service:moving_price_details_box_amount'
        )}`}</UqTypography>
      ) : null}
      {additionalInfo && (
        <UqTypography variant="body2" color="text.secondary" align="center">{`${t(
          'moving_service:moving_price_details_info'
        )}: ${additionalInfo}`}</UqTypography>
      )}
    </Layout>
  )
}

export default MovingPrice
