import { combineReducers } from '@reduxjs/toolkit'

import contactDetails from 'Features/contactDetails'
import demoDetails from 'Features/demoDetails'
import snackbar from 'Features/snackbar'
import movingService from 'Features/movingService'

const rootReducer = combineReducers({
  contactDetails,
  demoDetails,
  snackbar,
  movingService
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
