import React, { useState, useEffect } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { UqBackdrop, UqCircularProgress } from '@uniqore/wrapper'
import { editDemoDetails, editDemoTheme, DemoType } from 'Features/demoDetails'
import { updateMovingServiceValue, editMovingService, MovingService } from 'Features/movingService'

const SEND_DEMO_LINK_CLICKED = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const SERVICE_ENGAGEMENT = gql`
  query serviceEngagement($id: ID!) {
    serviceEngagement(id: $id) {
      metadata {
        key
        value
      }
    }
  }
`

const DemoHandler: React.FC = () => {
  const { engagementid, demoid, status } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [demo, setDemo] = useState<DemoType | null>(null)
  const [lang, setLang] = useState<string | null>(null)
  const [demoDetails, setDemoDetails] = useState<unknown | null>(null)

  const [demoClicked] = useMutation(SEND_DEMO_LINK_CLICKED, {
    variables: {
      id: process.env.REACT_APP_DEMO_LINK_CLICKED_ID,
      form: {
        engagementId: engagementid
      }
    }
  })

  const { loading } = useQuery(SERVICE_ENGAGEMENT, {
    variables: {
      id: engagementid
    },
    onCompleted(result) {
      if (result.serviceEngagement) {
        const findDemo = result.serviceEngagement.metadata.find(
          (m: { key: string; value: string }) => m.key === 'demo'
        )
        const findColors = result.serviceEngagement.metadata.find(
          (m: { key: string; value: string }) => m.key === 'demoColors'
        )
        const findLanguage = result.serviceEngagement.metadata.find(
          (m: { key: string; value: string }) => m.key === 'language'
        )
        const findDemoDetails = result.serviceEngagement.metadata.find(
          (m: { key: string; value: string }) => m.key === demoid
        )
        if (findDemo) {
          dispatch(editDemoDetails({ key: 'demoEngagementId', value: engagementid || '' }))
          if (findColors) {
            dispatch(editDemoTheme({ key: 'primary', value: findColors.value.primary.main }))
            dispatch(editDemoTheme({ key: 'secondary', value: findColors.value.secondary.main }))
          }
          setDemoDetails(findDemoDetails)
          setDemo(findDemo.value)
          setLang(findLanguage.value || 'fi')
        } else {
          navigate('/fi/welcome')
        }
      } else {
        navigate('/fi/welcome')
      }
    },
    onError() {
      navigate('/fi/welcome')
    }
  })

  useEffect(() => {
    if (demo && lang) {
      demoClicked()
      if (demo === 'moving-service') {
        handleMovingServiceDemo()
      }
    }
  }, [demo, lang])

  const handleMovingServiceDemo = () => {
    if (demoDetails) {
      const { key, value } = demoDetails as {
        key: string
        value: MovingService & { '@airtableDemoId'?: string }
      }
      dispatch(editMovingService({ key: 'demoId', value: key }))
      dispatch(updateMovingServiceValue(value))
    }
    if (status === 'offer') {
      navigate(`/${lang}/${demo}/offer`)
    } else if (status === 'order') {
      navigate(`/${lang}/${demo}/moving-start`)
    } else {
      navigate(`/${lang}/${demo}/welcome`)
    }
  }

  return (
    <>
      <UqBackdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
    </>
  )
}

export default DemoHandler
