import { createTheme } from '@mui/material/styles'
import { UqTheme } from '@uniqore/theme'

const theme = createTheme({
  ...UqTheme,
  palette: {
    primary: {
      dark: '#000112de',
      light: '#305562de',
      main: '#002c38dd'
    },
    secondary: {
      dark: '#00701a',
      light: '#76d275',
      main: '#43a047ff'
    },
    error: {
      dark: '#b20049',
      light: '#ff78a2',
      main: '#EA4274'
    },
    info: {
      dark: '#007ecb',
      light: '#83dfff',
      main: '#44ADFF'
    },
    warning: {
      dark: '#c76a07',
      light: '#ffca6e',
      main: '#FF993F'
    },
    success: {
      dark: '#43a047',
      light: '#a9ffa5',
      main: '#76D275'
    },
    common: {
      black: '#212121',
      white: '#FFFFFF'
    },
    text: {
      disabled: '#002c3861',
      primary: '#002c38DE',
      secondary: '#002c3899'
    }
  },
  typography: {
    h5: {
      fontWeight: 300
    },
    h6: {
      fontWeight: 400
    },
    overline: {
      textTransform: 'uppercase'
    },
    button: {
      textTransform: 'none',
      fontWeight: 400
    }
  },
  custom: {
    borderColor: '#E6EAEB'
  }
})

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      borderColor: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom: {
      borderColor: string
    }
  }
}

export default theme
