import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav, Image } from '@uniqore/module'
import {
  UqBox,
  UqTypography,
  UqButton,
  UqListItem,
  UqListItemText,
  UqListItemAvatar,
  UqAvatar,
  UqFade
} from '@uniqore/wrapper'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'

const MovingOngoing: React.FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation('moving_service')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)

  const [showElement, setShowElement] = useState<boolean>(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElement(true)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      bottom={
        <UqFade in={showElement}>
          <div>
            <FlowNav
              actionComponents={[
                <UqButton
                  key={1}
                  onClick={() => navigate(`/${lang}/moving-service/moving-ready`)}
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                  endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
                >
                  {t('moving_service:moving_ongoing_button')}
                </UqButton>
              ]}
            />
          </div>
        </UqFade>
      }
    >
      <Image
        src="https://assets.website-files.com/62862937efdfeff32720287c/6311bd40f4eb4a6461612932_Space-ongoing-uniqore.svg"
        width={80}
        center
        marginTop={48}
      />
      <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
        {t('moving_service:moving_ongoing_title')}
      </UqTypography>
      <UqTypography variant="body1" color="text.secondary" align="center">
        {t('moving_service:moving_ongoing_desc')}
      </UqTypography>
      <UqBox
        marginVertical={24}
        sx={{
          backgroundColor: '#fafafa',
          border: `1px solid #b3c7cd`,
          borderRadius: '4px',
          padding: '8px 16px',
          paddingTop: '4px'
        }}
      >
        <UqListItem disableGutters alignItems="flex-start">
          <UqListItemAvatar>
            <UqAvatar
              alt="TP"
              src="https://media-exp1.licdn.com/dms/image/C4D03AQHdZT6l0o6_Sg/profile-displayphoto-shrink_200_200/0/1614175727249?e=1657756800&v=beta&t=1mSeMZRQoIMEESceN0nXSV4tan6NjtYPrNtSjJiPLGE"
            />
          </UqListItemAvatar>
          <UqListItemText
            primary="Tomi Pyrhönen"
            secondary={
              <>
                <UqTypography
                  variant="body2"
                  component="span"
                  color="text.secondary"
                  sx={{ display: 'block' }}
                >
                  +358 40 735 0400
                </UqTypography>
                <UqTypography
                  variant="body2"
                  component="span"
                  color="text.secondary"
                  sx={{ display: 'block' }}
                >
                  tomi.pyrhonen@sofokus.com
                </UqTypography>
              </>
            }
            primaryTypographyProps={{ color: 'text.primary' }}
          />
        </UqListItem>
      </UqBox>
    </Layout>
  )
}

export default MovingOngoing
