import React, { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav } from '@uniqore/module'
import { UqTypography, UqButton, UqContainer } from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { ColorResult } from 'react-color'
import ColorPicker, { setAlphaToHex } from 'Components/Common/ColorPicker'
import {
  editDemoTheme,
  setColorsToDefaults,
  defaultPrimaryMain,
  defaultSecondaryMain
} from 'Features/demoDetails'

const Branding: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['main', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary, secondary } = useTypedSelector(state => state.demoDetails.colors)

  const updateColor = (color: ColorResult, key: 'primary' | 'secondary') => {
    dispatch(editDemoTheme({ key, value: color.hex }))
  }

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      top={<NavBar navigateTo={`/${lang}/welcome`} text={t('common:back')} progressValue={50} />}
      bottom={
        <FlowNav
          actionComponents={[
            {
              text: t('common:forward'),
              color: 'primary',
              onClick: () => navigate(`/${lang}/contact-details`)
            }
          ]}
        />
      }
    >
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('main:branding_title')}
      </UqTypography>
      <UqTypography variant="body1" color="text.secondary" align="center" marginBottom={24}>
        {t('main:branding_description')}
      </UqTypography>
      <UqContainer maxWidth="sm">
        <ColorPicker
          label={t('main:branding_primary_label')}
          color={primary.main}
          updateColor={color => updateColor(color, 'primary')}
        />
        <ColorPicker
          label={t('main:branding_secondary_label')}
          color={secondary.main}
          updateColor={color => updateColor(color, 'secondary')}
        />
      </UqContainer>
      <UqButton
        onClick={() => dispatch(setColorsToDefaults())}
        disabled={primary.main === defaultPrimaryMain && secondary.main === defaultSecondaryMain}
        fullWidth
        variant="text"
        size="large"
        color="error"
      >
        {t('main:branding_default_colors')}
      </UqButton>
    </Layout>
  )
}

export default Branding
