import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav, Image } from '@uniqore/module'
import {
  UqBox,
  UqButton,
  UqTypography,
  UqRating,
  UqDialog,
  UqDialogTitle,
  UqDialogContent,
  UqDialogActions,
  UqTextField
} from '@uniqore/wrapper'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import { CHANGE_MOVING_SERVICE_STATUS } from 'Sections/MovingService'

const MovingReady: React.FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation('moving_service')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const demoEngagementId = useTypedSelector(state => state.demoDetails.demoEngagementId)
  const { demoId } = useTypedSelector(state => state.movingService)

  const [ratingValue, setRatingValue] = useState<number | null>(3)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [feedback, setFeedback] = useState<string>('')
  const [currentFeedback, setCurrentFeedback] = useState<string>('')

  const [changeStatus] = useMutation(CHANGE_MOVING_SERVICE_STATUS)

  const handleGoForward = () => {
    changeStatus({
      variables: {
        id: process.env.REACT_APP_DEMO_MOVING_SERVICE_STATUS_CHANGE_ID,
        form: {
          engagementId: demoEngagementId,
          status: 'done',
          demoId
        }
      }
    })
    navigate(`/${lang}/moving-service/thank-you`)
  }

  return (
    <>
      <Layout
        containerBackground={setAlphaToHex(primary.main)}
        bottom={
          <FlowNav
            actionComponents={[
              {
                color: 'primary',
                text: t('moving_service:moving_ready_button'),
                fullWidth: true,
                variant: 'contained',
                onClick: handleGoForward
              }
            ]}
          />
        }
      >
        <Image
          src="https://assets.website-files.com/62862937efdfeff32720287c/630dc89704d2d6803b6f4a3b_Space-Feedback-uniqore.svg"
          width={80}
          center
          marginTop={48}
        />
        <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
          {t('moving_service:moving_ready_title')}
        </UqTypography>
        <UqTypography variant="body1" color="text.primary" align="center" marginTop={16}>
          {t('moving_service:moving_ready_desc')}
        </UqTypography>
        <UqTypography variant="caption" color="text.secondary" align="center" component="div">
          {t('moving_service:moving_ready_rating')}
        </UqTypography>
        <div style={{ textAlign: 'center' }}>
          <UqRating
            value={Number(ratingValue)}
            onChange={(_e, value) => {
              setRatingValue(value)
            }}
            size="large"
            sx={{
              fontSize: '2.2rem'
            }}
            max={5}
            marginVertical={16}
          />
        </div>
        {!feedback ? (
          <UqButton
            variant="text"
            color="secondary"
            size="large"
            fullWidth
            onClick={() => setDialogOpen(true)}
          >
            {t('moving_service:moving_ready_rating_button')}
          </UqButton>
        ) : (
          <UqBox
            sx={{
              backgroundColor: '#ECF6ED4D',
              border: `1px solid #E3F1E3`,
              borderRadius: '4px',
              padding: '8px'
            }}
          >
            <UqTypography
              variant="subtitle2"
              align="center"
              color="text.disabled"
            >{`"${feedback}"`}</UqTypography>
            <UqTypography
              variant="subtitle2"
              color="secondary.main"
              align="center"
              marginTop={8}
              onClick={() => setDialogOpen(true)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {t('moving_service:moving_ready_rating_button_edit')}
            </UqTypography>
          </UqBox>
        )}
      </Layout>
      <UqDialog open={dialogOpen} fullWidth>
        <UqDialogTitle>{t('moving_service:moving_ready_dialog_title')}</UqDialogTitle>
        <UqDialogContent>
          <UqTextField
            placeholder={t('moving_service:moving_ready_dialog_input')}
            variant="outlined"
            size="medium"
            color="primary"
            rows={5}
            multiline
            fullWidth
            marginVertical={4}
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
          />
        </UqDialogContent>
        <UqDialogActions>
          <UqButton
            variant="text"
            color="secondary"
            onClick={() => {
              setFeedback(currentFeedback)
              setDialogOpen(false)
            }}
          >
            {t('moving_service:moving_ready_dialog_button_cancel')}
          </UqButton>
          <UqButton
            variant="contained"
            color="secondary"
            onClick={() => {
              setCurrentFeedback(feedback)
              setDialogOpen(false)
            }}
          >
            {t('moving_service:moving_ready_dialog_button_submit')}
          </UqButton>
        </UqDialogActions>
      </UqDialog>
    </>
  )
}

export default MovingReady
