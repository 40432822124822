import React, { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import short from 'short-uuid'
import { gql, useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav } from '@uniqore/module'
import {
  UqTypography,
  UqList,
  UqListItem,
  UqListItemText,
  UqListItemIcon,
  UqBackdrop,
  UqCircularProgress
} from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import LoginRoundedIcon from '@mui/icons-material/LoginRounded'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import { editSnackbar } from 'Features/snackbar'
import { editMovingService } from 'Features/movingService'

const SEND_MOVING_SERVICE_DETAILS = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const Summary: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['moving_service', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const demoEngagementId = useTypedSelector(state => state.demoDetails.demoEngagementId)
  const {
    movingType,
    moveFromCity,
    moveToCity,
    surfaceAreaHome,
    surfaceAreaCompany,
    moveFromFloor,
    moveToFloor,
    moveFromElevator,
    moveToElevator,
    needMoveBoxes,
    movingBoxAmount,
    movingDate,
    additionalInfo,
    needPackageService,
    firstName,
    lastName,
    phone,
    email,
    companyName,
    companyId,
    companyAddress,
    companyZip,
    companyCity
  } = useTypedSelector(state => state.movingService)

  const handleGoForward = () => {
    if (movingType === 'home') {
      navigate(`/${lang}/moving-service/payment-method`)
    }
    if (movingType === 'company') {
      navigate(`/${lang}/moving-service/order-received`)
    }
  }

  const [sendDemoDetails, { loading }] = useMutation(SEND_MOVING_SERVICE_DETAILS, {
    onCompleted(result) {
      if (result && !result.ingestForm.errors) {
        handleGoForward()
      } else {
        dispatch(
          editSnackbar({
            message: t('common:error'),
            type: 'error'
          })
        )
      }
    },
    onError() {
      dispatch(
        editSnackbar({
          message: t('common:error'),
          type: 'error'
        })
      )
    }
  })

  const handleSendDemoDetails = () => {
    const demoId = short.generate()
    const setMovingServiceDemoDetails = {
      engagementId: demoEngagementId,
      demoId: demoId,
      movingType,
      moveFromCity,
      moveToCity,
      surfaceAreaHome,
      surfaceAreaCompany,
      moveFromFloor,
      moveToFloor,
      moveFromElevator,
      moveToElevator,
      movingBoxAmount,
      movingDate,
      additionalInfo,
      needPackageService,
      firstName,
      lastName,
      phone,
      email,
      companyName,
      companyId,
      companyAddress,
      companyZip,
      companyCity
    }

    dispatch(editMovingService({ key: 'demoId', value: demoId }))
    sendDemoDetails({
      variables: {
        id: process.env.REACT_APP_DEMO_MOVING_SERVICE_DETAILS_ID,
        form: setMovingServiceDemoDetails
      }
    })
  }

  return (
    <>
      <UqBackdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
      <Layout
        containerBackground={setAlphaToHex(primary.main)}
        top={
          <NavBar
            navigateTo={
              movingType === 'home'
                ? `/${lang}/moving-service/moving-price`
                : `/${lang}/moving-service/billing-details`
            }
            text={t('common:back')}
            progressValue={100}
          />
        }
        bottom={
          <FlowNav
            actionComponents={[
              {
                text:
                  movingType === 'home'
                    ? t('moving_service:summary_button_home')
                    : t('moving_service:summary_button_company'),
                color: 'secondary',
                disabled: loading,
                onClick: handleSendDemoDetails
              }
            ]}
          />
        }
      >
        <UqTypography variant="h6" color="text.primary" align="center">{`${t(
          'moving_service:summary_title'
        )}: ${
          movingType === 'home'
            ? t('moving_service:summary_title_option_home')
            : t('moving_service:summary_title_option_company')
        }`}</UqTypography>

        <UqList>
          <UqListItem divider alignItems="flex-start">
            <UqListItemIcon>
              <LogoutRoundedIcon />
            </UqListItemIcon>
            <UqListItemText
              primary={t('moving_service:summary_moving_from')}
              secondary={
                <>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${t('moving_service:summary_moving_from_city')}: ${moveFromCity}`}
                  </UqTypography>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${t('moving_service:summary_moving_from_surface')}: ${
                      movingType === 'home' ? surfaceAreaHome : surfaceAreaCompany
                    }`}
                  </UqTypography>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${t('moving_service:summary_moving_from_floor')}: ${moveFromFloor}`}
                  </UqTypography>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${
                      moveFromElevator
                        ? t('moving_service:summary_elevator_true')
                        : t('moving_service:summary_elevator_false')
                    }`}
                  </UqTypography>
                  {movingType === 'home' && needMoveBoxes && movingBoxAmount > 0 ? (
                    <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>{`${t(
                      'moving_service:summary_moving_from_box'
                    )}: ${movingBoxAmount} ${t(
                      'moving_service:summary_moving_from_box_unit'
                    )}`}</UqTypography>
                  ) : null}
                </>
              }
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
          </UqListItem>
          <UqListItem divider alignItems="flex-start">
            <UqListItemIcon>
              <LoginRoundedIcon />
            </UqListItemIcon>
            <UqListItemText
              primary={t('moving_service:summary_moving_to')}
              secondary={
                <>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${t('moving_service:summary_moving_to_city')}: ${moveToCity}`}
                  </UqTypography>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${t('moving_service:summary_moving_to_floor')}: ${moveToFloor}`}
                  </UqTypography>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${
                      moveToElevator
                        ? t('moving_service:summary_elevator_true')
                        : t('moving_service:summary_elevator_false')
                    }`}
                  </UqTypography>
                </>
              }
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
          </UqListItem>
          <UqListItem divider alignItems="flex-start">
            <UqListItemIcon>
              <CalendarTodayRoundedIcon />
            </UqListItemIcon>
            <UqListItemText
              primary={t('moving_service:summary_date_info')}
              secondary={
                <>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${t('moving_service:summary_moving_date')}: ${movingDate}`}
                  </UqTypography>
                  {additionalInfo && (
                    <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                      {`"${additionalInfo}"`}
                    </UqTypography>
                  )}
                </>
              }
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
          </UqListItem>
          <UqListItem divider alignItems="flex-start">
            <UqListItemIcon>
              <AccountCircleOutlinedIcon />
            </UqListItemIcon>
            <UqListItemText
              primary={t('moving_service:summary_contact_details')}
              secondary={
                <>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${firstName} ${lastName}`}
                  </UqTypography>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${phone}`}
                  </UqTypography>
                  <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                    {`${email}`}
                  </UqTypography>
                </>
              }
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
          </UqListItem>
          <UqListItem alignItems="flex-start">
            <UqListItemIcon>
              <ReceiptLongOutlinedIcon />
            </UqListItemIcon>
            <UqListItemText
              primary={
                movingType === 'home'
                  ? t('moving_service:summary_pricing')
                  : t('moving_service:summary_billing')
              }
              secondary={
                movingType === 'home' ? (
                  <>
                    <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                      {t('moving_service:summary_home_move')}
                    </UqTypography>
                    <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                      {t('moving_service:summary_payment_method')}
                    </UqTypography>
                  </>
                ) : (
                  <>
                    <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                      {`${companyName} (${companyId})`}
                    </UqTypography>
                    <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                      {`${companyAddress}`}
                    </UqTypography>
                    <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                      {`${companyZip} ${companyCity}`}
                    </UqTypography>
                    {needPackageService && (
                      <UqTypography variant="body2" component="span" sx={{ display: 'block' }}>
                        {t('moving_service:summary_need_box')}
                      </UqTypography>
                    )}
                  </>
                )
              }
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
          </UqListItem>
        </UqList>
      </Layout>
    </>
  )
}

export default Summary
