import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav } from '@uniqore/module'
import {
  UqTypography,
  UqTextField,
  UqFormGroup,
  UqFormControlLabel,
  UqCheckbox,
  UqBackdrop,
  UqCircularProgress
} from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import { validateEmail } from 'Utils/helpers'
import { editContactDetails, ContactDetailsSchema } from 'Features/contactDetails'
import { editSnackbar } from 'Features/snackbar'
import { DemoType } from 'Features/demoDetails'

interface DemoDetailsFormSchema {
  contactDetails: ContactDetailsSchema
  demo: DemoType
  demoColors: {
    primary: {
      main: string
    }
    secondary: {
      main: string
    }
  }
  language: string
}

const SEND_DEMO_CONTACT_DETAILS = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const ContactDetails: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['main', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary, secondary } = useTypedSelector(state => state.demoDetails.colors)
  const contactDetails = useTypedSelector(state => state.contactDetails)

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)

  const [firstNameValid, setFirstNameValid] = useState<string>('')
  const [lastNameValid, setLastNameValid] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (contactDetails.firstName === '') {
      setFirstNameValid(t('common:error_input'))
      valid = false
    } else {
      setFirstNameValid('')
    }
    if (contactDetails.lastName === '') {
      setLastNameValid(t('common:error_input'))
      valid = false
    } else {
      setLastNameValid('')
    }
    if (!validateEmail(contactDetails.email)) {
      setEmailValid(t('common:error_input_email'))
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  const [sendDemo, { loading }] = useMutation(SEND_DEMO_CONTACT_DETAILS, {
    onCompleted(result) {
      if (result && !result.ingestForm.errors) {
        navigate(`/${lang}/demo-link-sent`)
      } else {
        dispatch(
          editSnackbar({
            message: t('common:error'),
            type: 'error'
          })
        )
      }
    },
    onError() {
      dispatch(
        editSnackbar({
          message: t('common:error'),
          type: 'error'
        })
      )
    }
  })

  const handleSendDemoEmail = () => {
    if (validateFields()) {
      const setDemoDetails: DemoDetailsFormSchema = {
        contactDetails,
        demo: 'moving-service',
        demoColors: {
          primary: {
            main: primary.main
          },
          secondary: {
            main: secondary.main
          }
        },
        language: lang || 'fi'
      }

      sendDemo({
        variables: {
          id: process.env.REACT_APP_DEMO_CONTACT_DETAILS_ID,
          form: setDemoDetails
        }
      })
    }
  }

  return (
    <>
      <UqBackdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
      <Layout
        containerBackground={setAlphaToHex(primary.main)}
        top={
          <NavBar navigateTo={`/${lang}/branding`} text={t('common:back')} progressValue={100} />
        }
        bottom={
          <FlowNav
            actionComponents={[
              {
                text: t('common:forward'),
                color: 'primary',
                disabled: !acceptTerms || loading,
                onClick: handleSendDemoEmail
              }
            ]}
          />
        }
      >
        <UqTypography variant="h6" color="text.primary" align="center">
          {t('main:contact_title')}
        </UqTypography>
        <UqTypography variant="body1" color="text.secondary" align="center">
          {t('main:contact_description')}
        </UqTypography>
        <UqTextField
          name="fname"
          autoComplete="given-name"
          value={contactDetails.firstName}
          onChange={e => dispatch(editContactDetails({ key: 'firstName', value: e.target.value }))}
          error={firstNameValid ? true : false}
          helperText={firstNameValid}
          label={t('main:contact_firstName_label')}
          variant="outlined"
          size="medium"
          fullWidth
          marginTop={24}
          marginBottom={8}
        />
        <UqTextField
          name="lname"
          autoComplete="family-name"
          value={contactDetails.lastName}
          onChange={e => dispatch(editContactDetails({ key: 'lastName', value: e.target.value }))}
          error={lastNameValid ? true : false}
          helperText={lastNameValid}
          label={t('main:contact_lastName_label')}
          variant="outlined"
          size="medium"
          fullWidth
          marginVertical={8}
        />
        <UqTextField
          value={contactDetails.company}
          onChange={e => dispatch(editContactDetails({ key: 'company', value: e.target.value }))}
          label={t('main:contact_company_label')}
          variant="outlined"
          size="medium"
          fullWidth
          marginVertical={8}
        />
        <UqTextField
          name="email"
          autoComplete="email"
          value={contactDetails.email}
          onChange={e => dispatch(editContactDetails({ key: 'email', value: e.target.value }))}
          error={emailValid ? true : false}
          helperText={emailValid}
          label={t('main:contact_email_label')}
          variant="outlined"
          size="medium"
          fullWidth
          marginVertical={8}
        />
        <UqFormGroup marginTop={8}>
          <UqFormControlLabel
            control={
              <UqCheckbox
                checked={acceptTerms}
                onChange={e => setAcceptTerms(e.target.checked)}
                size="medium"
                color="primary"
              />
            }
            label={
              <UqTypography>
                {t('main:contact_terms')}
                {` `}
                <a
                  href="https://www.uniqore.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('main:contact_terms_link')}
                </a>
              </UqTypography>
            }
          />
        </UqFormGroup>
      </Layout>
    </>
  )
}

export default ContactDetails
