import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { format, parse, addDays } from 'date-fns'
import { useTypedSelector } from 'Store'
import { Layout, Image, FlowNav } from '@uniqore/module'
import {
  UqBox,
  UqButton,
  UqTypography,
  UqListItem,
  UqListItemText,
  UqListItemIcon,
  UqFade
} from '@uniqore/wrapper'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'

const OrderConfirmed: React.FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation('moving_service')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const {
    movingType,
    movingDate,
    moveFromCity,
    moveToCity,
    needMoveBoxes,
    movingBoxAmount,
    needPackageService
  } = useTypedSelector(state => state.movingService)

  const [showElement, setShowElement] = useState<boolean>(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElement(true)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      bottom={
        <FlowNav
          actionComponents={[
            <UqFade key={1} in={showElement} style={{ width: '100%' }}>
              <div style={{ width: '100%' }}>
                <UqButton
                  onClick={() => navigate(`/${lang}/moving-service/moving-start`)}
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                  endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
                >
                  {t('moving_service:order_confirmed_button')}
                </UqButton>
              </div>
            </UqFade>
          ]}
        />
      }
    >
      <Image
        src="https://assets.website-files.com/62862937efdfeff32720287c/630dc8979f2fad3d67f2eeb5_Space-Verified-uniqore.svg"
        width={80}
        center
        marginTop={48}
        marginBottom={24}
      />
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('moving_service:order_confirmed_title')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('moving_service:order_confirmed_desc')}
      </UqTypography>
      <UqBox
        marginVertical={24}
        sx={{
          backgroundColor: '#fafafa',
          border: `1px solid #b3c7cd`,
          borderRadius: '4px',
          padding: '8px 16px',
          paddingTop: '4px'
        }}
      >
        <UqListItem disableGutters alignItems="flex-start">
          <UqListItemIcon>
            <InfoRoundedIcon sx={{ color: '#80a2ac' }} />
          </UqListItemIcon>
          <UqListItemText
            primary={`${
              movingType === 'home'
                ? t('moving_service:order_confirmed_home')
                : t('moving_service:order_confirmed_company')
            } ${movingDate}`}
            secondary={
              <>
                {movingType === 'home' && needMoveBoxes && movingBoxAmount > 0 ? (
                  <UqTypography
                    variant="body2"
                    component="span"
                    color="text.secondary"
                    sx={{ display: 'block' }}
                  >{`${t('moving_service:order_confirmed_boxes')} ${movingBoxAmount} ${t(
                    'moving_service:order_confirmed_boxes_unit'
                  )}`}</UqTypography>
                ) : null}
                {movingType === 'company' && needPackageService && (
                  <UqTypography
                    variant="body2"
                    component="span"
                    color="text.secondary"
                    sx={{ display: 'block' }}
                  >
                    {`${t('moving_service:order_confirmed_box_service')} ${format(
                      addDays(parse(movingDate, 'dd.MM.yyyy', new Date()), -1),
                      'dd.MM.yyyy'
                    )}`}
                  </UqTypography>
                )}
                <UqTypography
                  variant="body2"
                  component="span"
                  color="text.secondary"
                  sx={{ display: 'block' }}
                >
                  {`${moveFromCity} - ${moveToCity}`}
                </UqTypography>
              </>
            }
            primaryTypographyProps={{ color: 'text.primary' }}
          />
        </UqListItem>
      </UqBox>
    </Layout>
  )
}

export default OrderConfirmed
