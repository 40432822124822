import React, { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { format, parse, addDays } from 'date-fns'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav, Image } from '@uniqore/module'
import { UqTypography, UqDivider, UqBackdrop, UqCircularProgress } from '@uniqore/wrapper'
import { CHANGE_MOVING_SERVICE_STATUS } from 'Sections/MovingService'
import { setAlphaToHex } from 'Components/Common/ColorPicker'

const Offer: React.FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation('moving_service')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const demoEngagementId = useTypedSelector(state => state.demoDetails.demoEngagementId)
  const {
    demoId,
    companyName,
    movingDate,
    surfaceAreaCompany,
    moveFromCity,
    moveToCity,
    needPackageService
  } = useTypedSelector(state => state.movingService)

  const [changeStatus, { loading }] = useMutation(CHANGE_MOVING_SERVICE_STATUS)

  const handleGoForward = () => {
    changeStatus({
      variables: {
        id: process.env.REACT_APP_DEMO_MOVING_SERVICE_STATUS_CHANGE_ID,
        form: {
          engagementId: demoEngagementId,
          status: 'order',
          demoId
        }
      }
    })
    navigate(`/${lang}/moving-service/order-confirmed`)
  }

  return (
    <>
      <UqBackdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <UqCircularProgress color="inherit" />
      </UqBackdrop>

      <Layout
        containerBackground={setAlphaToHex(primary.main)}
        bottom={
          <FlowNav
            actionComponents={[
              {
                text: t('moving_service:offer_button'),
                color: 'secondary',
                size: 'large',
                fullWidth: true,
                onClick: handleGoForward
              }
            ]}
          />
        }
      >
        <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
          {t('moving_service:offer_title')}: {companyName}
        </UqTypography>
        <UqTypography variant="body2" color="text.secondary" align="center">
          {`${t('moving_service:offer_desc_1')} ${format(
            addDays(new Date(), 14),
            'dd.MM.yyyy'
          )} ${t('moving_service:offer_desc_2')}`}
        </UqTypography>
        <Image
          src="https://assets.website-files.com/62862937efdfeff32720287c/630dc8975fffcc6b0a2dfee3_Space-Email-check-uniqore.svg"
          width={60}
          center
          marginVertical={24}
        />
        <UqTypography variant="h6" color="text.primary" align="center">
          {t('moving_service:offer_price')}
        </UqTypography>
        <UqTypography variant="body2" color="text.secondary" align="center">
          {t('moving_service:offer_taxless')}
        </UqTypography>
        <UqTypography variant="body2" color="text.secondary" align="center">
          {t('moving_service:offer_tax')}
        </UqTypography>
        <UqDivider variant="fullWidth" light marginVertical={16} />
        <UqTypography variant="body2" color="text.secondary" align="center">
          {`${t('moving_service:offer_office')}: ${surfaceAreaCompany}`}
        </UqTypography>
        <UqTypography variant="body2" color="text.secondary" align="center">
          {`${moveFromCity} - ${moveToCity}`}
        </UqTypography>
        <UqTypography variant="body2" color="text.secondary" align="center">
          {`${t('moving_service:offer_move_date')}: ${movingDate}`}
        </UqTypography>
        {needPackageService && (
          <UqTypography variant="body2" color="text.secondary" align="center">
            {`${t('moving_service:offer_box_service')}: ${format(
              addDays(parse(movingDate, 'dd.MM.yyyy', new Date()), -1),
              'dd.MM.yyyy'
            )}`}
          </UqTypography>
        )}
        <UqTypography variant="body2" color="text.secondary" align="center">
          {t('moving_service:offer_payment')}
        </UqTypography>
      </Layout>
    </>
  )
}

export default Offer
