import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type DemoType = 'moving-service'

export const defaultPrimaryMain = '#002c38'
export const defaultSecondaryMain = '#43a047'

interface DemoColors {
  primary: {
    main: string
  }
  secondary: {
    main: string
  }
}

interface DemoDetailsSchema {
  demoEngagementId: string
  colors: DemoColors
}

const initialState: DemoDetailsSchema = {
  demoEngagementId: '',
  colors: {
    primary: {
      main: defaultPrimaryMain
    },
    secondary: {
      main: defaultSecondaryMain
    }
  }
}

const demoDetailsSlice = createSlice({
  name: 'demoDetails',
  initialState,
  reducers: {
    editDemoDetails: (
      state,
      action: PayloadAction<{ key: keyof Omit<DemoDetailsSchema, 'colors'>; value: string }>
    ) => {
      const { key, value } = action.payload
      state[key] = value
    },
    editDemoTheme: (state, action: PayloadAction<{ key: keyof DemoColors; value: string }>) => {
      const { key, value } = action.payload
      state.colors[key].main = value
    },
    setColorsToDefaults: state => {
      state.colors.primary.main = defaultPrimaryMain
      state.colors.secondary.main = defaultSecondaryMain
    }
  }
})

export const { editDemoDetails, editDemoTheme, setColorsToDefaults } = demoDetailsSlice.actions
export default demoDetailsSlice.reducer
