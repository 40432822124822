import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTypedSelector } from 'Store'

const DemoDetailsListener: React.FC = () => {
  const navigate = useNavigate()
  const demoEngagementId = useTypedSelector(state => state.demoDetails.demoEngagementId)

  useEffect(() => {
    if (demoEngagementId === '') {
      navigate('/fi/welcome')
    }
  }, [demoEngagementId])

  return null
}

export default DemoDetailsListener
