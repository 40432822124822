import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Collapse } from '@mui/material'
import { SketchPicker, ColorResult, RGBColor } from 'react-color'
import { UqTextField, UqInputAdornment } from '@uniqore/wrapper'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

export const setAlphaToHex = (color: string) => {
  return `${color}33`
}

const ColorContainer = styled('div')(() => ({
  display: 'flex',
  marginBottom: '16px'
}))

const IndicatorContainer = styled('div')(() => ({
  marginTop: '8px'
}))

const InputContainer = styled('div')(() => ({
  flexGrow: 1,
  paddingLeft: '16px'
}))

const RoundColorIndicator = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  height: '40px',
  width: '40px',
  borderRadius: '50%',
  cursor: 'pointer',
  border: `1px solid ${theme.custom.borderColor}`,
  backgroundColor: 'black'
}))

// The round indicator's style background color needs to be string
const convertRgbObjectToString = (rgb: RGBColor | string): string => {
  if (typeof rgb === 'string') {
    return rgb
  }
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
}

interface ColorPickerProps {
  label: string
  color: string
  updateColor: (color: ColorResult) => void
  style?: React.CSSProperties
}

const ColorPicker: React.FC<ColorPickerProps> = ({ label, color, updateColor, style }) => {
  const [expand, setExpand] = useState<boolean>(false)

  return (
    <ColorContainer style={style}>
      <IndicatorContainer>
        <RoundColorIndicator
          onClick={() => setExpand(!expand)}
          style={{ backgroundColor: convertRgbObjectToString(color) }}
        />
      </IndicatorContainer>
      <InputContainer>
        <UqTextField
          label={label}
          value={color}
          onClick={() => setExpand(!expand)}
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <UqInputAdornment position="end" sx={{ cursor: 'pointer' }}>
                {expand ? (
                  <ArrowDropUpIcon sx={{ color: theme => theme.palette.text.secondary }} />
                ) : (
                  <ArrowDropDownIcon sx={{ color: theme => theme.palette.text.secondary }} />
                )}
              </UqInputAdornment>
            )
          }}
        />
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <SketchPicker
            color={color}
            onChange={color => updateColor(color)}
            disableAlpha
            width="300"
          />
        </Collapse>
      </InputContainer>
    </ColorContainer>
  )
}

export default ColorPicker
