import React, { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from '@mui/material/styles'
import Theme from 'Theme'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout, Image, FlowNav } from '@uniqore/module'
import { UqTypography, UqAppBar, UqToolbar, UqIconButton, UqLink } from '@uniqore/wrapper'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'

const Welcome: React.FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['main', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  return (
    <ThemeProvider theme={Theme}>
      <Layout
        containerBackground="#ECF6ED"
        top={
          <UqAppBar position="relative" elevation={0} color="inherit">
            <UqToolbar>
              <UqIconButton
                href={`https://meet.uniqore.com/${lang}`}
                color="inherit"
                size="small"
                edge="start"
                sx={{ mr: 1 }}
              >
                <ArrowBackIosRoundedIcon />
              </UqIconButton>
              <UqTypography variant="subtitle1" marginhorizontal={0} sx={{ flexGrow: 1 }}>
                {t('common:back')}
              </UqTypography>
              <UqTypography variant="caption" sx={{ fontSize: '16px' }}>
                <UqLink
                  onClick={() => navigate(`/fi/welcome`)}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontWeight: i18n.language === 'fi' ? 'bolder' : 'normal'
                  }}
                >
                  FI{` `}
                </UqLink>
                /
                <UqLink
                  onClick={() => navigate(`/en/welcome`)}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontWeight: i18n.language === 'en' ? 'bolder' : 'normal'
                  }}
                >
                  {` `}EN
                </UqLink>
              </UqTypography>
            </UqToolbar>
          </UqAppBar>
        }
        bottom={
          <FlowNav
            actionComponents={[
              {
                text: t('main:welcome_button'),
                color: 'primary',
                onClick: () => navigate(`/${lang}/branding`)
              }
            ]}
          />
        }
      >
        <Image
          src="https://assets.website-files.com/62862937efdfeff32720287c/630dd11e92151dc248c01148_Space-Welcome1-uniqore.svg"
          marginTop={48}
          marginBottom={24}
          center
        />
        <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
          {t('main:welcome_title')}
        </UqTypography>
        <UqTypography variant="body1" color="text.secondary" align="center">
          {t('main:welcome_description')}
        </UqTypography>
      </Layout>
    </ThemeProvider>
  )
}

export default Welcome
