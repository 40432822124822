import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Layout, Image, FlowNav } from '@uniqore/module'
import { UqTypography, UqButton, UqFade } from '@uniqore/wrapper'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'

const OrderReceived: React.FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation('moving_service')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const { firstName } = useTypedSelector(state => state.movingService)

  const [showElement, setShowElement] = useState<boolean>(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElement(true)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      bottom={
        <UqFade in={showElement}>
          <div>
            <FlowNav
              actionComponents={[
                <UqButton
                  key={1}
                  onClick={() => navigate(`/${lang}/moving-service/offer`)}
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                  endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
                >
                  {t('moving_service:order_received_button')}
                </UqButton>
              ]}
            />
          </div>
        </UqFade>
      }
    >
      <Image
        src="https://assets.website-files.com/62862937efdfeff32720287c/630dc8974e0ef54eba33a440_Space-Success-uniqore.svg"
        width={100}
        center
        marginTop={96}
        marginBottom={10}
      />
      <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
        {t('moving_service:order_received_title')} {firstName}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('moving_service:order_received_desc')}
      </UqTypography>
    </Layout>
  )
}

export default OrderReceived
