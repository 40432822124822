import React from 'react'
import { useNavigate } from 'react-router-dom'
import { UqAppBar, UqToolbar, UqIconButton, UqTypography, UqLinearProgress } from '@uniqore/wrapper'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded'

interface NavBarProps {
  navigateTo: string
  text?: string
  progressValue?: number
  fillFields?: () => void
}

const NavBar: React.FC<NavBarProps> = ({ navigateTo, text, progressValue = 50, fillFields }) => {
  const navigate = useNavigate()

  return (
    <>
      <UqAppBar position="relative" elevation={0} color="inherit">
        <UqToolbar>
          <UqIconButton
            onClick={() => navigate(navigateTo)}
            color="inherit"
            size="medium"
            edge="start"
            sx={{ mr: 1 }}
          >
            <ArrowBackIosRoundedIcon />
          </UqIconButton>
          <UqTypography variant="body1" marginhorizontal={0} sx={{ flexGrow: 1 }}>
            {text}
          </UqTypography>
          {fillFields && (
            <UqIconButton
              onClick={fillFields}
              edge="end"
              size="medium"
              color="inherit"
              sx={{ ml: 1 }}
            >
              <AutoFixHighRoundedIcon />
            </UqIconButton>
          )}
        </UqToolbar>
      </UqAppBar>
      <UqLinearProgress value={progressValue} variant="determinate" color="secondary" />
    </>
  )
}

export default NavBar
