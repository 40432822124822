import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav } from '@uniqore/module'
import { UqTypography, UqTextField } from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import { editMovingService } from 'Features/movingService'
import { validateEmail } from 'Utils/helpers'

const ContactPerson: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['moving_service', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const { movingType, firstName, lastName, phone, email } = useTypedSelector(
    state => state.movingService
  )

  const [firstNameValid, setFirstNameValid] = useState<string>('')
  const [lastNameValid, setLastNameValid] = useState<string>('')
  const [phoneValid, setPhoneValid] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (firstName === '') {
      setFirstNameValid(t('common:error_input'))
      valid = false
    } else {
      setFirstNameValid('')
    }
    if (lastName === '') {
      setLastNameValid(t('common:error_input'))
      valid = false
    } else {
      setLastNameValid('')
    }
    if (phone === '') {
      setPhoneValid(t('common:error_input'))
      valid = false
    } else {
      setPhoneValid('')
    }
    if (email !== '') {
      if (!validateEmail(email)) {
        setEmailValid(t('common:error_input_email'))
        valid = false
      } else {
        setEmailValid('')
      }
    }
    return valid
  }

  const handleForward = () => {
    if (validateFields()) {
      if (movingType === 'home') {
        navigate(`/${lang}/moving-service/moving-price`)
      }
      if (movingType === 'company') {
        navigate(`/${lang}/moving-service/billing-details`)
      }
    }
  }

  const handleFillFields = () => {
    dispatch(editMovingService({ key: 'firstName', value: 'Matti' }))
    dispatch(editMovingService({ key: 'lastName', value: 'Meikäläinen' }))
    dispatch(editMovingService({ key: 'phone', value: '0501234567' }))
  }

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      top={
        <NavBar
          fillFields={handleFillFields}
          navigateTo={`/${lang}/moving-service/moving-date`}
          text={t('common:back')}
          progressValue={60}
        />
      }
      bottom={
        <FlowNav
          actionComponents={[
            {
              text: t('common:forward'),
              color: 'primary',
              onClick: handleForward
            }
          ]}
        />
      }
    >
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('moving_service:contact_person_title')}
      </UqTypography>
      {movingType === 'company' && (
        <UqTypography variant="body2" color="text.secondary" align="center">
          {t('moving_service:contact_person_desc')}
        </UqTypography>
      )}
      <UqTextField
        name="fname"
        autoComplete="given-name"
        value={firstName}
        onChange={e => dispatch(editMovingService({ key: 'firstName', value: e.target.value }))}
        error={firstNameValid ? true : false}
        helperText={firstNameValid}
        label={t('moving_service:contact_person_firstname_input')}
        variant="outlined"
        size="medium"
        fullWidth
        marginTop={16}
        marginBottom={8}
      />
      <UqTextField
        name="lname"
        autoComplete="family-name"
        value={lastName}
        onChange={e => dispatch(editMovingService({ key: 'lastName', value: e.target.value }))}
        error={lastNameValid ? true : false}
        helperText={lastNameValid}
        label={t('moving_service:contact_person_lastname_input')}
        variant="outlined"
        size="medium"
        fullWidth
        marginVertical={8}
      />
      <UqTextField
        type="tel"
        name="phone"
        autoComplete="tel"
        value={phone}
        onChange={e =>
          dispatch(
            editMovingService({
              key: 'phone',
              value: e.target.value
            })
          )
        }
        error={phoneValid ? true : false}
        helperText={phoneValid}
        label={t('moving_service:contact_person_phone_input')}
        variant="outlined"
        size="medium"
        fullWidth
        marginVertical={8}
      />
      <UqTextField
        name="email"
        autoComplete="email"
        value={email}
        onChange={e => dispatch(editMovingService({ key: 'email', value: e.target.value }))}
        error={emailValid ? true : false}
        helperText={emailValid || t('moving_service:contact_person_email_helper')}
        label={t('moving_service:contact_person_email_input')}
        variant="outlined"
        size="medium"
        fullWidth
        marginVertical={8}
      />
    </Layout>
  )
}

export default ContactPerson
