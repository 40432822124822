import React, { useState, useEffect, useMemo } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { format, addDays } from 'date-fns'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav } from '@uniqore/module'
import {
  UqTypography,
  UqTextField,
  UqMenuItem,
  UqList,
  UqListItem,
  UqListItemText,
  UqSwitch
} from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import { editMovingService } from 'Features/movingService'

const handleDates = (): string[] => {
  const today = new Date()
  const dates: Date[] = []
  for (let i = 7; i < 14; i++) {
    dates.push(addDays(today, i))
  }
  return dates.map(d => format(d, 'dd.MM.yyyy'))
}

const MovingDate: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['moving_service', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const { movingType, movingDate, additionalInfo, needPackageService } = useTypedSelector(
    state => state.movingService
  )

  const dates = useMemo(() => handleDates(), [])

  const [movingDateValid, setMovingDateValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (movingDate === '') {
      setMovingDateValid(t('common:error_input'))
      valid = false
    } else {
      setMovingDateValid('')
    }
    return valid
  }

  const handleForward = () => {
    if (validateFields()) {
      navigate(`/${lang}/moving-service/contact-person`)
    }
  }

  const handleFillFields = () => {
    dispatch(editMovingService({ key: 'movingDate', value: dates[0] }))
  }

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      top={
        <NavBar
          fillFields={handleFillFields}
          navigateTo={`/${lang}/moving-service/moving-to`}
          text={t('common:back')}
          progressValue={40}
        />
      }
      bottom={
        <FlowNav
          actionComponents={[
            {
              text: t('common:forward'),
              color: 'primary',
              onClick: handleForward
            }
          ]}
        />
      }
    >
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('moving_service:moving_date_title')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('moving_service:moving_date_desc')}
      </UqTypography>
      <UqList>
        {movingType === 'company' && (
          <UqListItem disableGutters>
            <UqListItemText
              primary={t('moving_service:moving_date_switch_primary')}
              secondary={t('moving_service:moving_date_switch_secondary')}
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
            <UqSwitch
              edge="end"
              onChange={e =>
                dispatch(editMovingService({ key: 'needPackageService', value: e.target.checked }))
              }
              checked={needPackageService}
            />
          </UqListItem>
        )}
      </UqList>
      <UqTextField
        select
        label={t('moving_service:moving_date_input_date')}
        fullWidth
        variant="outlined"
        marginTop={16}
        marginBottom={8}
        value={movingDate}
        onChange={e => dispatch(editMovingService({ key: 'movingDate', value: e.target.value }))}
        error={movingDateValid ? true : false}
        helperText={movingDateValid}
      >
        {dates.map((option, i) => (
          <UqMenuItem key={i} value={option}>
            {option}
          </UqMenuItem>
        ))}
      </UqTextField>
      <UqTextField
        value={additionalInfo}
        onChange={e =>
          dispatch(editMovingService({ key: 'additionalInfo', value: e.target.value }))
        }
        marginVertical={8}
        variant="outlined"
        size="medium"
        color="primary"
        label={t('moving_service:moving_date_input_info')}
        multiline
        rows={5}
        fullWidth
      />
    </Layout>
  )
}

export default MovingDate
