import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { createEpicMiddleware } from 'redux-observable'
import { createSelectorHook, useDispatch } from 'react-redux'

import rootReducer, { RootState } from './rootReducer'

const epicMiddleware = createEpicMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(epicMiddleware)
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line
    const { default: newRootReducer } = require('./rootReducer')
    store.replaceReducer(newRootReducer)
  })
}

export type { RootState } // re-export
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const useAppDispatch = () => useDispatch<typeof store.dispatch>() // Export a hook that can be reused to resolve types

export const useTypedSelector = createSelectorHook<RootState>()

export default useTypedSelector
