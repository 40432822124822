import React, { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav, Image } from '@uniqore/module'
import { UqTypography } from '@uniqore/wrapper'
import { setAlphaToHex } from 'Components/Common/ColorPicker'

const DemoLinkSent: React.FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation('main')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const email = useTypedSelector(state => state.contactDetails.email)

  // Navigate back to home-page if email doesn't exist in store
  useEffect(() => {
    if (!email) {
      navigate('/fi/welcome')
    }
  }, [email])

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      bottom={
        <FlowNav
          actionComponents={[
            {
              text: t('link_sent_button'),
              variant: 'text',
              color: 'primary',
              onClick: () => navigate(`/${lang}/contact-details`)
            }
          ]}
        />
      }
    >
      <Image
        src="https://assets.website-files.com/62862937efdfeff32720287c/630dc8971c19f0055b8202e5_Space-Email-uniqore.svg"
        width={100}
        center
        marginTop={96}
        marginBottom={24}
      />
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('link_sent_title')}
      </UqTypography>
      <UqTypography variant="body1" color="text.secondary" align="center">
        {t('link_sent_description')}
      </UqTypography>
    </Layout>
  )
}

export default DemoLinkSent
