import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav, Image } from '@uniqore/module'
import { UqTypography, UqButton, UqSnackbar, UqAlert, UqAlertProps } from '@uniqore/wrapper'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import { RWebShare } from 'react-web-share'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

const Alert = React.forwardRef<HTMLDivElement, UqAlertProps>(function Alert(props, ref) {
  return <UqAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ThankYou: React.FC = () => {
  const { lang } = useParams()
  const { t } = useTranslation('moving_service')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)

  return (
    <>
      <Layout
        containerBackground={setAlphaToHex(primary.main)}
        bottom={
          <FlowNav
            actionDirection="column"
            actionComponents={[
              <UqButton
                key={1}
                href={`https://meet.uniqore.com/${lang}/date`}
                variant="contained"
                size="large"
                color="secondary"
                fullWidth
                marginVertical={4}
              >
                {t('moving_service:thank_you_button_meet')}
              </UqButton>,
              isMobile ? (
                <RWebShare
                  key={2}
                  data={{
                    title: 'Uniqore-demo',
                    text: t('moving_service:thank_you_share_text'),
                    url: `${process.env.REACT_APP_DEMO_DOMAIN}${lang}/welcome`
                  }}
                >
                  <UqButton
                    variant="text"
                    size="large"
                    color="primary"
                    fullWidth
                    startIcon={<ShareOutlinedIcon />}
                    marginVertical={4}
                  >
                    {t('moving_service:thank_you_button_share')}
                  </UqButton>
                </RWebShare>
              ) : (
                <UqButton
                  key={3}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${t('moving_service:thank_you_share_text')} ${
                        process.env.REACT_APP_DEMO_DOMAIN
                      }${lang}/welcome`
                    )
                    setOpenSnackbar(true)
                  }}
                  variant="text"
                  size="large"
                  color="primary"
                  fullWidth
                  startIcon={<ShareOutlinedIcon />}
                  marginVertical={4}
                >
                  {t('moving_service:thank_you_button_share')}
                </UqButton>
              )
            ]}
          />
        }
      >
        <Image
          src="https://assets.website-files.com/62862937efdfeff32720287c/6311bd402e256f2b22352b27_Space-finished-uniqore.svg"
          width={80}
          center
          marginTop={48}
          marginBottom={24}
        />
        <UqTypography variant="h6" color="text.primary" align="center" marginTop={24}>
          {t('moving_service:thank_you_title')}
        </UqTypography>
        <UqTypography variant="body1" color="text.secondary" align="center">
          {t('moving_service:thank_you_desc')}
        </UqTypography>
      </Layout>
      <UqSnackbar
        open={openSnackbar}
        autoHideDuration={8000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
      >
        <Alert icon={<FileCopyOutlinedIcon />} color="success">
          {t('moving_service:thank_you_alert')}
        </Alert>
      </UqSnackbar>
    </>
  )
}

export default ThankYou
