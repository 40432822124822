import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav } from '@uniqore/module'
import {
  UqTypography,
  UqTextField,
  UqMenuItem,
  UqSwitch,
  UqFormGroup,
  UqFormControlLabel
} from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import { editMovingService, citiesOptions, floorOptions } from 'Features/movingService'

const MovingTo: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['moving_service', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const { moveToCity, moveToFloor, moveToElevator } = useTypedSelector(state => state.movingService)

  const [moveToCityValid, setMoveToCityValid] = useState<string>('')
  const [moveToFloorValid, setMoveToFloorValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (moveToCity === '') {
      setMoveToCityValid(t('common:error_input'))
      valid = false
    } else {
      setMoveToCityValid('')
    }
    if (moveToFloor === '') {
      setMoveToFloorValid(t('common:error_input'))
      valid = false
    } else {
      setMoveToFloorValid('')
    }

    return valid
  }

  const handleForward = () => {
    if (validateFields()) {
      navigate(`/${lang}/moving-service/moving-date`)
    }
  }

  const handleFillFields = () => {
    dispatch(editMovingService({ key: 'moveToCity', value: 'Hämeenlinna' }))
    dispatch(editMovingService({ key: 'moveToFloor', value: '5' }))
  }

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      top={
        <NavBar
          fillFields={handleFillFields}
          navigateTo={`/${lang}/moving-service/moving-from`}
          text={t('common:back')}
          progressValue={20}
        />
      }
      bottom={
        <FlowNav
          actionComponents={[
            {
              text: t('common:forward'),
              color: 'primary',
              onClick: handleForward
            }
          ]}
        />
      }
    >
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('moving_service:moving_to_title')}
      </UqTypography>
      <UqTextField
        select
        label={t('moving_service:moving_to_input_city')}
        fullWidth
        variant="outlined"
        marginTop={16}
        marginBottom={8}
        value={moveToCity}
        onChange={e => dispatch(editMovingService({ key: 'moveToCity', value: e.target.value }))}
        error={moveToCityValid ? true : false}
        helperText={moveToCityValid}
      >
        {citiesOptions.map((option, i) => (
          <UqMenuItem key={i} value={option}>
            {option}
          </UqMenuItem>
        ))}
      </UqTextField>
      <UqTextField
        select
        label={t('moving_service:moving_to_input_floor')}
        fullWidth
        variant="outlined"
        marginVertical={8}
        value={moveToFloor}
        onChange={e => dispatch(editMovingService({ key: 'moveToFloor', value: e.target.value }))}
        error={moveToFloorValid ? true : false}
        helperText={moveToFloorValid}
      >
        {floorOptions.map((option, i) => (
          <UqMenuItem key={i} value={option}>
            {option}
          </UqMenuItem>
        ))}
      </UqTextField>
      <UqFormGroup marginVertical={8}>
        <UqFormControlLabel
          control={
            <UqSwitch
              checked={moveToElevator}
              onChange={e =>
                dispatch(editMovingService({ key: 'moveToElevator', value: e.target.checked }))
              }
              size="medium"
              color="primary"
            />
          }
          label={t('moving_service:moving_to_input_elevator')}
        />
      </UqFormGroup>
    </Layout>
  )
}

export default MovingTo
