import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav, Image } from '@uniqore/module'
import { UqTypography, UqButton, UqFade } from '@uniqore/wrapper'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'

const PaymentMethod: React.FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation('moving_service')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)

  const [showElement, setShowElement] = useState<boolean>(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElement(true)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      bottom={
        <UqFade in={showElement}>
          <div>
            <FlowNav
              actionComponents={[
                <UqButton
                  key={1}
                  onClick={() => navigate(`/${lang}/moving-service/order-confirmed`)}
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                  endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
                >
                  {t('moving_service:payment_method_button')}
                </UqButton>
              ]}
            />
          </div>
        </UqFade>
      }
    >
      <Image
        src="https://uploads-ssl.webflow.com/62862937efdfeff32720287c/628e0436d8567a9c72aba615_payment1.svg"
        width={100}
        center
        marginTop={96}
        marginBottom={24}
        marginHorizontal={24}
      />
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('moving_service:payment_method_title')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('moving_service:payment_method_desc')}
      </UqTypography>
    </Layout>
  )
}

export default PaymentMethod
