import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useTypedSelector } from 'Store'
import { Theme as ThemeSchema, createTheme } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import Theme from 'Theme'
import Snackbar from 'Components/Common/Snackbar'
import Welcome from 'Sections/Main/Welcome'
import Branding from 'Sections/Main/Branding'
import ContactDetails from 'Sections/Main/ContactDetails'
import DemoLinkSent from 'Sections/Main/DemoLinkSent'
import DemoHandler from 'Sections/Main/DemoHandler'
import MovingService from 'Sections/MovingService'

const App: React.FC = () => {
  const [projectTheme, setProjectTheme] = useState<ThemeSchema>(Theme)
  const { primary, secondary } = useTypedSelector(state => state.demoDetails.colors)

  useEffect(() => {
    setProjectTheme(
      createTheme({
        ...Theme,
        palette: {
          ...Theme.palette,
          primary: {
            main: primary.main
          },
          secondary: {
            main: secondary.main
          }
        }
      })
    )
  }, [primary.main, secondary.main])

  return (
    <ThemeProvider theme={projectTheme}>
      <BrowserRouter>
        <Routes>
          <Route element={<Welcome />} path="/:lang/welcome" />
          <Route element={<Branding />} path="/:lang/branding" />
          <Route element={<ContactDetails />} path="/:lang/contact-details" />
          <Route element={<DemoLinkSent />} path="/:lang/demo-link-sent" />
          {['/demo/:engagementid', '/demo/:engagementid/:demoid/:status'].map(path => (
            <Route key={1} path={path} element={<DemoHandler />} />
          ))}
          {/* Demo: Moving Service */}
          <Route element={<MovingService />} path="/:lang/moving-service/*" />
          <Route path="*" element={<Navigate to="/fi/welcome" replace />} />
        </Routes>
      </BrowserRouter>
      <Snackbar />
    </ThemeProvider>
  )
}
export { App }
export default App
