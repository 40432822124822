import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ContactDetailsSchema {
  firstName: string
  lastName: string
  company: string
  email: string
}

const initialState: ContactDetailsSchema = {
  firstName: '',
  lastName: '',
  company: '',
  email: ''
}

const contactDetailsSlice = createSlice({
  name: 'contactDetails',
  initialState,
  reducers: {
    editContactDetails: (
      state,
      action: PayloadAction<{ key: keyof ContactDetailsSchema; value: string }>
    ) => {
      const { key, value } = action.payload
      state[key] = value
    }
  }
})

export const { editContactDetails } = contactDetailsSlice.actions
export default contactDetailsSlice.reducer
