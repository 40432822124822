import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const citiesOptions: string[] = [
  'Espoo',
  'Hämeenlinna',
  'Helsinki',
  'Hyvinkää',
  'Järvenpää',
  'Joensuu',
  'Jyväskylä',
  'Kokkola',
  'Kotka',
  'Kouvola',
  'Kuopio',
  'Lahti',
  'Lappeenranta',
  'Lohja',
  'Mikkeli',
  'Oulu',
  'Pori',
  'Porvoo',
  'Rovaniemi',
  'Salo',
  'Seinäjoki',
  'Tampere',
  'Turku',
  'Vaasa',
  'Vantaa'
]

export const surfaceAreaHomeOptions: string[] = [
  '<50 m2',
  '51-100 m2',
  '101-150 m2',
  '151-200 m2',
  '> 200 m2'
]

export const surfaceAreaCompanyOptions: string[] = [
  '<100 m2',
  '101-200 m2',
  '201-400 m2',
  '401-600 m2',
  '601-1000 m2',
  '> 1000 m2'
]

export const floorOptions: string[] = [
  '1 (pohjakerros)',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
]

export type MovingType = 'home' | 'company'

export interface MovingService {
  demoId: string
  movingType: MovingType
  moveFromCity: string
  moveToCity: string
  surfaceAreaHome: string
  surfaceAreaCompany: string
  moveFromFloor: string
  moveToFloor: string
  moveFromElevator: boolean
  moveToElevator: boolean
  needMoveBoxes: boolean
  movingBoxAmount: number
  movingDate: string
  additionalInfo: string
  needPackageService: boolean
  firstName: string
  lastName: string
  phone: string
  email: string
  companyName: string
  companyId: string
  companyAddress: string
  companyZip: string
  companyCity: string
}

const initialState: MovingService = {
  demoId: '',
  movingType: 'home',
  moveFromCity: '',
  moveToCity: '',
  surfaceAreaHome: '',
  surfaceAreaCompany: '',
  moveFromFloor: '',
  moveToFloor: '',
  moveFromElevator: false,
  moveToElevator: false,
  needMoveBoxes: false,
  movingBoxAmount: 0,
  movingDate: '',
  additionalInfo: '',
  needPackageService: false,
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  companyName: '',
  companyId: '',
  companyAddress: '',
  companyZip: '',
  companyCity: ''
}

type EditMovingService = Omit<MovingService, 'movingType'>

const movingServiceSlice = createSlice({
  name: 'movingService',
  initialState,
  reducers: {
    editMovingType: (state, action: PayloadAction<MovingType>) => {
      state.movingType = action.payload
    },
    editMovingService: (
      state,
      action: PayloadAction<{ key: keyof EditMovingService; value: string | boolean | number }>
    ) => {
      const { key, value } = action.payload
      if (
        key === 'moveFromElevator' ||
        key === 'moveToElevator' ||
        key === 'needMoveBoxes' ||
        key === 'needPackageService'
      ) {
        state[key] = value as boolean
      } else if (key === 'movingBoxAmount') {
        state[key] = value as number
      } else {
        state[key] = value as string
      }
    },
    updateMovingServiceValue: (
      state,
      action: PayloadAction<MovingService & { '@airtableDemoId'?: string }>
    ) => {
      const data = JSON.parse(JSON.stringify(action.payload))
      delete data['@airtableDemoId']
      const updateState = { ...state, ...data }
      return updateState
    }
  }
})

export const { editMovingType, editMovingService, updateMovingServiceValue } =
  movingServiceSlice.actions
export default movingServiceSlice.reducer
