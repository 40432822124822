import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav, Image } from '@uniqore/module'
import {
  UqTypography,
  UqButton,
  UqDialog,
  UqDialogTitle,
  UqDialogContent,
  UqDialogContentText,
  UqDialogActions
} from '@uniqore/wrapper'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import { editMovingType, MovingType } from 'Features/movingService'

const Welcome: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation('moving_service')

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)

  const [dialogOpen, setDialogOpen] = useState<boolean>(true)

  const handleClick = (movingType: MovingType) => {
    dispatch(editMovingType(movingType))
    navigate(`/${lang}/moving-service/moving-from`)
  }

  return (
    <>
      <Layout
        containerBackground={setAlphaToHex(primary.main)}
        bottom={
          <FlowNav
            actionDirection="column"
            actionComponents={[
              <UqButton
                key={1}
                variant="outlined"
                fullWidth
                color="primary"
                size="large"
                startIcon={<HomeRoundedIcon />}
                marginVertical={4}
                onClick={() => handleClick('home')}
              >
                {t('welcome_button_home')}
              </UqButton>,
              <UqButton
                key={2}
                variant="outlined"
                fullWidth
                color="primary"
                size="large"
                marginVertical={4}
                startIcon={<ApartmentRoundedIcon />}
                onClick={() => handleClick('company')}
              >
                {t('welcome_button_company')}
              </UqButton>
            ]}
          />
        }
      >
        <Image
          src="https://assets.website-files.com/62862937efdfeff32720287c/630dd11e92151dc248c01148_Space-Welcome1-uniqore.svg"
          width={100}
          center
          marginTop={48}
          marginBottom={24}
        />
        <UqTypography variant="h6" color="text.primary" align="center">
          {t('welcome_title')}
        </UqTypography>
        <UqTypography variant="body2" color="text.secondary" align="center">
          {t('welcome_desc')}
        </UqTypography>
      </Layout>
      <UqDialog open={dialogOpen}>
        <UqDialogTitle style={{ display: 'flex', alignItems: 'center' }}>
          {t('welcome_dialog_title')}
        </UqDialogTitle>
        <UqDialogContent>
          <UqDialogContentText>
            {t('welcome_dialog_desc_1')} <AutoFixHighRoundedIcon sx={{ marginBottom: '-6px' }} />{' '}
            {t('welcome_dialog_desc_2')}
          </UqDialogContentText>
        </UqDialogContent>
        <UqDialogActions>
          <UqButton onClick={() => setDialogOpen(false)} variant="contained" color="primary">
            {t('welcome_dialog_button')}
          </UqButton>
        </UqDialogActions>
      </UqDialog>
    </>
  )
}

export default Welcome
