import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Layout, FlowNav } from '@uniqore/module'
import { UqTypography, UqTextField } from '@uniqore/wrapper'
import NavBar from 'Components/Common/NavBar'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import { editMovingService } from 'Features/movingService'

const BillingDetails: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { lang } = useParams()
  const { t } = useTranslation(['moving_service', 'common'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { primary } = useTypedSelector(state => state.demoDetails.colors)
  const { companyName, companyId, companyAddress, companyZip, companyCity } = useTypedSelector(
    state => state.movingService
  )

  const [companyNameValid, setCompanyNameValid] = useState<string>('')
  const [companyIdValid, setCompanyIdValid] = useState<string>('')
  const [companyAddressValid, setCompanyAddressValid] = useState<string>('')
  const [companyZipValid, setCompanyZipValid] = useState<string>('')
  const [companyCityValid, setCompanyCityValid] = useState<string>('')

  const validateFields = () => {
    let valid = true

    if (companyName === '') {
      setCompanyNameValid(t('common:error_input'))
      valid = false
    } else {
      setCompanyNameValid('')
    }
    if (companyId === '') {
      setCompanyIdValid(t('common:error_input'))
      valid = false
    } else {
      setCompanyIdValid('')
    }
    if (companyAddress === '') {
      setCompanyAddressValid(t('common:error_input'))
      valid = false
    } else {
      setCompanyAddressValid('')
    }
    if (companyZip === '') {
      setCompanyZipValid(t('common:error_input'))
      valid = false
    } else {
      setCompanyZipValid('')
    }
    if (companyCity === '') {
      setCompanyCityValid(t('common:error_input'))
      valid = false
    } else {
      setCompanyCityValid('')
    }
    return valid
  }

  const handleForward = () => {
    if (validateFields()) {
      navigate(`/${lang}/moving-service/summary`)
    }
  }

  const handleFillFields = () => {
    dispatch(editMovingService({ key: 'companyName', value: 'Acme Inc' }))
    dispatch(editMovingService({ key: 'companyId', value: '123456-7' }))
    dispatch(editMovingService({ key: 'companyAddress', value: 'Katuosoite 1' }))
    dispatch(editMovingService({ key: 'companyZip', value: '00100' }))
    dispatch(editMovingService({ key: 'companyCity', value: 'Helsinki' }))
  }

  return (
    <Layout
      containerBackground={setAlphaToHex(primary.main)}
      top={
        <NavBar
          fillFields={handleFillFields}
          navigateTo={`/${lang}/moving-service/contact-person`}
          text={t('common:back')}
          progressValue={80}
        />
      }
      bottom={
        <FlowNav
          actionComponents={[
            {
              text: t('common:forward'),
              color: 'primary',
              onClick: handleForward
            }
          ]}
        />
      }
    >
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('moving_service:billing_details_title')}
      </UqTypography>
      <UqTextField
        value={companyName}
        onChange={e =>
          dispatch(
            editMovingService({
              key: 'companyName',
              value: e.target.value
            })
          )
        }
        error={companyNameValid ? true : false}
        helperText={companyNameValid}
        label={t('moving_service:billing_details_input_company')}
        variant="outlined"
        size="medium"
        fullWidth
        marginTop={16}
        marginBottom={8}
      />
      <UqTextField
        value={companyId}
        onChange={e =>
          dispatch(
            editMovingService({
              key: 'companyId',
              value: e.target.value
            })
          )
        }
        error={companyIdValid ? true : false}
        helperText={companyIdValid}
        label={t('moving_service:billing_details_input_business_id')}
        variant="outlined"
        size="medium"
        fullWidth
        marginVertical={8}
      />
      <UqTextField
        name="ship-address"
        autoComplete="shipping street-address"
        value={companyAddress}
        onChange={e =>
          dispatch(
            editMovingService({
              key: 'companyAddress',
              value: e.target.value
            })
          )
        }
        error={companyAddressValid ? true : false}
        helperText={companyAddressValid}
        label={t('moving_service:billing_details_input_address')}
        variant="outlined"
        size="medium"
        fullWidth
        marginVertical={8}
      />
      <UqTextField
        name="ship-zip"
        autoComplete="shipping postal-code"
        value={companyZip}
        onChange={e =>
          dispatch(
            editMovingService({
              key: 'companyZip',
              value: e.target.value
            })
          )
        }
        error={companyZipValid ? true : false}
        helperText={companyZipValid}
        label={t('moving_service:billing_details_input_zip')}
        variant="outlined"
        size="medium"
        fullWidth
        marginVertical={8}
      />
      <UqTextField
        name="ship-city"
        autoComplete="shipping locality"
        value={companyCity}
        onChange={e =>
          dispatch(
            editMovingService({
              key: 'companyCity',
              value: e.target.value
            })
          )
        }
        error={companyCityValid ? true : false}
        helperText={companyCityValid}
        label={t('moving_service:billing_details_input_city')}
        variant="outlined"
        size="medium"
        fullWidth
        marginVertical={8}
      />
    </Layout>
  )
}

export default BillingDetails
