import React from 'react'
import { gql } from '@apollo/client'
import { Routes, Route } from 'react-router-dom'
import DemoDetailsListener from 'Sections/Main/DemoDetailsListener'
import Welcome from './Pages/Welcome'
import MovingFrom from './Pages/MovingFrom'
import MovingTo from './Pages/MovingTo'
import MovingDate from './Pages/MovingDate'
import ContactPerson from './Pages/ContactPerson'
import MovingPrice from './Pages/MovingPrice'
import BillingDetails from './Pages/BillingDetails'
import Summary from './Pages/Summary'
import PaymentMethod from './Pages/PaymentMethod'
import OrderReceived from './Pages/OrderReceived'
import Offer from './Pages/Offer'
import OrderConfirmed from './Pages/OrderConfirmed'
import MovingStart from './Pages/MovingStart'
import MovingOngoing from './Pages/MovingOngoing'
import MovingReady from './Pages/MovingReady'
import ThankYou from './Pages/ThankYou'

export const CHANGE_MOVING_SERVICE_STATUS = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const MovingService: React.FC = () => {
  return (
    <>
      <Routes>
        <Route element={<Welcome />} path="/welcome" />
        <Route element={<MovingFrom />} path="/moving-from" />
        <Route element={<MovingTo />} path="/moving-to" />
        <Route element={<MovingDate />} path="/moving-date" />
        <Route element={<ContactPerson />} path="/contact-person" />
        <Route element={<MovingPrice />} path="/moving-price" />
        <Route element={<BillingDetails />} path="/billing-details" />
        <Route element={<Summary />} path="/summary" />
        <Route element={<PaymentMethod />} path="/payment-method" />
        <Route element={<OrderReceived />} path="/order-received" />
        <Route element={<Offer />} path="/offer" />
        <Route element={<OrderConfirmed />} path="/order-confirmed" />
        <Route element={<MovingStart />} path="/moving-start" />
        <Route element={<MovingOngoing />} path="/moving-ongoing" />
        <Route element={<MovingReady />} path="/moving-ready" />
        <Route element={<ThankYou />} path="/thank-you" />
      </Routes>
      <DemoDetailsListener />
    </>
  )
}

export default MovingService
